
import {ICrudOption} from "@/types/m-ui-crud";
import {Component, Vue} from "vue-property-decorator";
import {
  orderReasonCreateApi,
  orderReasonModifyApi,
  orderReasonQueryApi,
  orderReasonRemoveApi
} from "@/apis/afterSales/orderReason";
import {IOrderReason} from "@/apis/afterSales/orderReason/types";

/**
 * @name: 售后管理-退款原因管理
 * @author: lili
 * @date: 2023-07-20 10:08
 * @description： 售后管理-退款原因管理
 * @update: 2023-07-20 10:08
 */
@Component({})
export default class RolePage extends Vue {

  activeName: string = 'first'
  // crud配置
  crudOption: ICrudOption = {
    dialogWidth: '500px',
    addTitle: '新增取消订单原因',
    editTitle: '编辑取消订单原因',
    searchBox: false,
    column: [
      {
        label: "序号",
        prop: "id",
        align: "left",
        addHide: true,
        editHide: true,
        viewHide: true,
      },
      {
        label: "原因",
        prop: "reason",
        align: "center",
        span: 24,
        maxlength: 20,
        rules: [
          {required: true, message: "请输入原因", trigger: 'blur'}
        ]
      },
      {
        label: "排序",
        prop: "sort",
        align: "center",
        span: 24,
        rules: [
          {
            required: true, trigger: 'blur',
            validator: (rule: any, value: any, callback: any) => {
              if (!value) {
                return callback(new Error("请输入排序"));
              } else if (!/(^[1-9]\d*$)/.test(value)) {
                callback(new Error("请输入排序为正整数!"));
              } else {
                callback();
              }
            },
          }
        ]
      },
      {
        label: "状态",
        prop: "status",
        align: "center",
        span: 24,
        type: "switch",
        slot: true,
        addHide: true,
        editHide: true,
        value: 1,
        dicData: [
          {
            label: "禁用",
            value: 0,
          },
          {
            label: "启用",
            value: 1,
          },
        ],
        rules: [
          {required: true, message: "请选择状态", trigger: 'blur'}
        ]
      }
    ],
  };
  // crud配置
  crudOption2: ICrudOption = {
    dialogWidth: '500px',
    addTitle: '新增申请售后原因',
    editTitle: '编辑申请售后原因',
    searchBox: false,
    column: [
      {
        label: "序号",
        prop: "id",
        align: "left",
        addHide: true,
        editHide: true,
        viewHide: true,
      },
      {
        label: "原因",
        prop: "reason",
        align: "center",
        span: 24,
        maxlength: 20,
        rules: [
          {required: true, message: "请输入原因", trigger: 'blur'}
        ]
      },
      {
        label: "排序",
        prop: "sort",
        align: "center",
        span: 24,
        rules: [
          {
            required: true, trigger: 'blur',
            validator: (rule: any, value: any, callback: any) => {
              if (!value) {
                return callback(new Error("请输入排序"));
              } else if (!/(^[1-9]\d*$)/.test(value)) {
                callback(new Error("请输入排序为正整数!"));
              } else {
                callback();
              }
            },
          }
        ]
      },
      {
        label: "状态",
        prop: "status",
        align: "center",
        span: 24,
        type: "switch",
        slot: true,
        addHide: true,
        editHide: true,
        value: 1,
        dicData: [
          {
            label: "禁用",
            value: 0,
          },
          {
            label: "启用",
            value: 1,
          },
        ],
        rules: [
          {required: true, message: "请选择状态", trigger: 'blur'}
        ]
      }
    ],
  };
  query: IOrderReason = {
    types: 1,//类型: 1=取消订单原因 2=申请售后原因
  }
  // 新增/编辑 表单
  form: Partial<IOrderReason> = {}
  // 数据总数
  total: number = 0
  // 列表
  data: IOrderReason[] = []

  /**
   * 查询列表
   */
  getList() {
    orderReasonQueryApi(this.query).then(e => {
      this.data = e;
    })
  }

  /**
   * 切换状态
   */
  handleSwitch(row: IOrderReason, val: number) {
    orderReasonModifyApi({id: row.id, status: val} as IOrderReason).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      })
      this.getList()
    })
  }


  /**
   * 新增
   * @param form
   */
  rowSave(form: IOrderReason, done: Function, loading: Function) {
    form.types = this.activeName == 'first' ? 1 : 2;
    orderReasonCreateApi(form).then(e => {
      done()
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    }).finally(() => {
      loading()
    })
  }

  /**
   * 修改
   * @param form
   */
  rowEdit(form: IOrderReason, done: Function, loading: Function) {
    orderReasonModifyApi(form).then(e => {
      done()
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    }).finally(() => {
      loading()
    })
  }

  /**
   * 删除
   */
  rowDel(row: IOrderReason, index: number) {
    orderReasonRemoveApi(row.id || '').then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    })
  }

  handleClick() {
    this.query.types = this.activeName == 'first' ? 1 : 2;
    this.crudOption.addTitle = this.activeName == 'first' ? '新增取消订单原因' : '新增申请售后原因'
    this.crudOption.editTitle = this.activeName == 'first' ? '编辑取消订单原因' : '编辑申请售后原因'
    this.getList()

  }

  mounted() {
    this.getList()
  }
}
