import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IOrderReason} from "@/apis/afterSales/orderReason/types";

/**
 * 分页查询
 * @param query 查询参数
 * @returns
 */
export const orderReasonQueryApi = (params:IOrderReason) => get<IOrderReason[]>("/mall/orderReason/queryByList",params)
/**
 * 创建
 * @param data 参数
 * @returns
 */
export const orderReasonCreateApi = (data: IOrderReason) =>  postJ("/mall/orderReason/create", data)
/**
 * 修改
 * @param data 参数
 * @returns
 */
export const orderReasonModifyApi = (data: IOrderReason) =>  postJ("/mall/orderReason/modify", data)
/**
 * 删除
 * @param id id
 * @returns
 */
export const orderReasonRemoveApi = (id: string) => get("/mall/orderReason/remove/" + id)
/**
 * 详情
 * @param id id
 * @returns
 */
export const orderReasonDetailApi = (id: string) => get("/mall/orderReason/detail/" + id);
